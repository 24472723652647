<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="手术后注意事项"/>
        <div class="content">
            <div class="page" style="background-color: #e6e6e6;padding:10px">
                <div>患者姓名：{{this.form.appoint.name}}</div>
                <div>手术日期：{{this.form.appoint.date}} {{this.form.appoint.time}}</div>
            </div>
            <div class="page" id="printMe">
                <div class="caption">
                    <h2>手术后注意事项</h2>
                </div>
                <div class="text">
                    <p>1、手术后务必在手术室护士同意后方可离开医院，离开医院时请确认已经被详细告知术后注意事项、复诊事宜等。</p>
                    <p>
                        2、常规手术，请于手术后72小时（三天）后自行打开敷料，以后每天早、晚在伤口缝针处外涂医用75%酒精或复方多粘菌素B等抗生素软膏（药店购买）两次，无需再外覆敷料。注意避免剧烈运动、牵拉伤口及出汗。伤口拆线前请勿沾水。</p>
                    <p>3、请患者于
                        <el-date-picker style="width: 130px" size="mini" v-model="form.advice_date" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" @change="setDate"/>
                        （手术后第 {{form.advice_day}} 天）上午8:00到8:30来手术室拆线或换药，切勿迟到以免贻误最佳处置时机。拆线或换药时不需要提前挂号，请当天携带就诊卡/医保卡/电子就诊卡直接来手术室门口分诊台取号，工作人员为您加号后，交费并排队等候叫号。
                    </p>
                    <p>4、术后一周内请不要吸烟、饮酒，饮食无特殊禁忌，少食辛辣刺激食物，如发现伤口异样或有不适，请及时与医生联系010-83573103（工作日上午10:00-12:00
                        工作日下午2:00-4:00）。如是非工作日或需紧急联系手术医生，可拨打010-83573273联系病房值班医生。</p>
                    <p>5、请按照医护要求按时返回皮肤科手术室复查伤口情况和疾病康复情况。 </p>
                    <p>6、取病理报告的时间是手术一周后的工作日10点到14点，请到皮科手术室门口分诊台凭本人身份证领取结果，结果保存3个月（自手术日算起）。</p>
                </div>
            </div>

            <div class="btn">
                <el-button type="primary" @click="sendmsg">保存并发送通知</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                adviceList: [
                    {
                        label: '3天',
                        day: 3,
                    },
                    {
                        label: '7天',
                        day: 7,
                    },
                    {
                        label: '10天',
                        day: 10,
                    },
                    {
                        label: '两周',
                        day: 14,
                    }
                ],
                form: {
                    appoint: {},
                    advice_day: 0,
                    advice_date: 0,
                }
            }
        },
        methods: {
            setDate(val) {
                console.log(val)
                let date1 = new Date(this.form.appoint.date)
                let date2 = new Date(val)
                let iDays = parseInt((date2 - date1) / 1000 / 60 / 60 /24)
                this.form.advice_day = iDays
            },
            async sendmsg() {
                if (!this.form.advice_date || this.form.advice_day<0) {
                    this.$message.error('请输入正确的日期')
                    return
                }
                const resp = await this.$http.post(`/surgery/advice/`, this.form)
                if (resp.data.code === 200) {
                    this.$message.success(resp.data.data)
                    await this.$router.replace('/main/advice')
                }
                else
                    this.$message.error(resp.data.msg)
            },
            async getData() {
                const resp = await this.$http.get(`/surgery/advice/${this.$route.query.id}`)
                this.form = resp.data.data
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .content {
        /*max-width: 900px;*/
        margin-top: 30px;

        .btn {
            text-align: center;
            margin-top: 30px;
        }
    }

    .page {
        margin: 0 auto;
        max-width: 900px;
        font-size: 16px;

        h2, h4 {
            margin: 0;
            padding: 0;
        }

        p, ul {
            margin: 0;
        }

        ul {
            margin-left: 0;
            padding-left: 20px;
        }

        li {
            margin-left: 0;
            padding-left: 0;
        }

        div.caption, div.map {
            text-align: center;
            padding: 10px;
        }

        div.map img {
            max-width: 400px;
        }
    }

    .text {
        line-height: 40px;
    }

    .checkbox-group {
        display: inline;
    }

    .checkbox {
        margin-right: 5px;
    }
</style>
